var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "vs-checkbox",
        {
          staticClass: "font-bold checkboxform",
          class: {
            "bg-white": !_vm.disabled,
            "bg-background-grey": _vm.disabled
          },
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.isChecked,
            callback: function($$v) {
              _vm.isChecked = $$v
            },
            expression: "isChecked"
          }
        },
        [
          _vm._t("default", function() {
            return [_vm._v(_vm._s(_vm.$t("Name Checkbox")))]
          })
        ],
        2
      ),
      _vm.info
        ? _c(
            "vx-tooltip",
            {
              staticClass: "absolute right-0 checkbox__tooltip",
              attrs: { text: _vm.info, position: "top" }
            },
            [
              _c("vs-icon", {
                attrs: {
                  icon: "help",
                  round: "",
                  bg: "white",
                  color: "primary"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }