<template>
    <div class="relative">
        <vs-checkbox :disabled="disabled" v-model="isChecked" class="font-bold checkboxform" :class="{ 'bg-white': !disabled, 'bg-background-grey': disabled }">
            <slot>{{ $t("Name Checkbox") }}</slot>
        </vs-checkbox>
        <vx-tooltip v-if="info" :text="info" position="top" class="absolute right-0 checkbox__tooltip">
            <vs-icon icon="help" round bg="white" color="primary"></vs-icon>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name: 'check-box-form',
    data() {
        return {
            isChecked: false
        }
    },
    props: {
        checked: Boolean,
        info: String,
        disabled: {
          type: Boolean,
          default: false
        }
    },
    created() {
        this.isChecked = this.checked;
    },
    watch: {
        isChecked: function (val) {
            this.$emit('isChecked',val)
        }
    },
}
</script>
<style>
    .checkboxform {
        @apply border-grey-light;
        border: 1px solid;
        border-radius: 8px;
    }
    .checkboxform .con-slot-label {
        border-left: 1px solid #dae1e7;
        padding: 7px 10px;
    }
    .checkboxform .vs-checkbox {
        margin: 7px;
    }
    .checkbox__tooltip {
        top:10px;
    }
</style>

