export default {
  set: (item,value) => {
    const localStorageData = localStorage.getItem(item); 
    if (localStorageData) {
        try {
            return JSON.parse(localStorageData);
        } catch(e) {
            localStorage.removeItem(item);
        }
    } else {
        localStorage.setItem(item, JSON.stringify(value));
        return value;
    }
  },
  get:(item) => {
    const localStorageData = localStorage.getItem(item); 
    return JSON.parse(localStorageData);
  },
  remove: (item) => {
    localStorage.removeItem(item);
  }
}
  


